import React from 'react';
import { Typography, Button } from 'antd';
import Dice from '../../pages/Dice';

const { Title, Text } = Typography;

const PlayerSection = ({
  currentPlayer,
  playerTotal,
  isMyTurn,
  diceValues,
  selectedDice,
  isRolling,
  rollCount,
  toggleDiceSelection,
  handleDiceRoll,
  gameId,
}) => {
  return (
    <div className="player-section">
      <div className="game-dice-container">
        <div className="dice-row">
          {diceValues.map((value, index) => (
            <Dice
              key={index}
              value={value}
              isSelected={selectedDice.includes(index)}
              isRolling={isRolling}
              onClick={() => isMyTurn && toggleDiceSelection(index)}
              disabled={!isMyTurn}
            />
          ))}
        </div>
        
        <div className="controls-row">
          <Button
            type="primary"
            onClick={handleDiceRoll}
            disabled={!gameId || rollCount >= 3 || !isMyTurn}
            className="w-32"
          >
            Roll Dice
          </Button>
          
          <Text className="text-gray-600">
            Roll Count: {rollCount}/3
          </Text>
          
          {!isMyTurn && (
            <Text className="text-gray-500 italic">
              Waiting for opponent's turn...
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerSection;